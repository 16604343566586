import 'regenerator-runtime/runtime';
import 'cross-fetch/polyfill';
import isBrowser from './utils/isBrowser';

if (isBrowser()) {
  document.addEventListener("DOMContentLoaded", () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js');
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      window.app.deferredPrompt = e;
      return false;
    });
  });
}
